export enum EPermissions {
  GET_LIST_CAMERA = 'list_cameras',
  ADD_CAMERA = 'add_camera',
  UPDATE_CAMERA = 'update_camera',
  DELETE_CAMERA = 'delete_camera',

  GET_LIST_STATION = 'list_stations',
  ADD_STATION = 'add_station',
  UPDATE_STATION = 'edit_station',
  DELETE_STATION = 'delete_station',

  GET_LIST_CUSTOMER = 'list_customers',
  ADD_CUSTOMER = 'add_customer',
  UPDATE_CUSTOMER = 'edit_customer',
  DELETE_CUSTOMER = 'delete_customer',

  LIST_ORDER = 'list_orders',
  ADD_ORDER = 'add_order',
  EDIT_ORDER = 'edit_order',
  DELETE_ORDER = 'delete_order',

  LIST_ORDER_CAMERA = 'list_order_cameras',
  ADD_ORDER_CAMERA = 'add_order_camera',
  EDIT_ORDER_CAMERA = 'edit_order_camera',
  DELETE_ORDER_CAMERA = 'delete_order_camera',

  LIST_ORDER_STATION = 'list_order_stations',
  ADD_ORDER_STATION = 'add_order_station',
  EDIT_ORDER_STATION = 'edit_order_station',
  DELETE_ORDER_STATION = 'delete_order_station',

  LIST_ORDER_MEMBER = 'list_order_member',
  ADD_ORDER_MEMBER = 'add_order_member',
  EDIT_ORDER_MEMBER = 'edit_order_member',
  DELETE_ORDER_MEMBER = 'delete_order_member',

  LIST_USER = 'list_users',
  INVITE_ADMIN_USER = 'invite_admin_user',
  INVITE_CUSTOMER_USER = 'invite_customer_user',
  UPDATE_USER = 'edit_user',
  DELETE_USER = 'delete_user',

  LIST_ORDER_RESOURCE = 'list_order_resource',
}

export enum EGpsStatus {
  DISCONNECT = 'disconnect',
  CONNECT = 'connect',
}

export enum ELevel {
  SUPER_ADMIN = 0,
  SYSTEM_ADMIN = 1,
  SYSTEM_MEMBER = 2,
  CUSTOMER_ADMIN = 3,
  CUSTOMER_MEMBER = 4,
}

export enum ENotificationEvent {
  // STATION_DISCONNECTED = 'station_disconnected',
  // STATION_CONNECTED = 'station_connected',
  // STATION_ENABLED = 'station_enabled',
  // STATION_DISABLED = 'station_disabled',

  STATION_STATUS_UPDATED = 'station_status_updated',

  STATION_GPS_DISCONNECTED = 'station_gps_disconnected',
  STATION_GPS_UPDATED = 'station_gps_updated',
  STATION_BATTERY_DISCONNECTED = 'station_battery_disconnected',
  STATION_BATTERY_UPDATED = 'station_battery_updated',
  STATION_MOBILE_NETWORK_DISCONNECTED = 'station_mobile_network_disconnected',
  STATION_MOBILE_NETWORK_UPDATED = 'station_mobile_network_updated',
  STATION_LIGHT_TURN_ON = 'station_light_turn_on',
  STATION_LIGHT_TURN_OFF = 'station_light_turn_off',
  STATION_CAMERA_TURN_ON = 'station_camera_turn_on',
  STATION_CAMERA_TURN_OFF = 'station_camera_turn_off',
  STATION_CAMERA_READY = 'station_camera_ready',
  STATION_CAMERA_TURN_ON_FAILED = 'station_camret_turn_on_failed',
  STATION_DELETED = 'station_deleted',
  STATION_WORKING_MODE_UPDATED = 'station_working_mode_updated',

  //camera
  CAMERA_CONNECTED = 'camera_connected',
  CAMERA_DISCONNECTED = 'camera_disconnected',
  CAMERA_ENABLED = 'camera_enabled',
  CAMERA_DISABLED = 'camera_disabled',
  CAMERA_RECORD_ERROR = 'camera_record_error',
  CAMERA_AI_ERROR = 'camera_ai_error',
  CAMERA_DELETED = 'camera_deleted',

  CUSTOMER_DELETED = 'customer_deleted',
  ORDER_DELETED = 'order_deleted',
  ORDER_MEMBER_DELETED = 'order_member_deleted',
  ORDER_STATION_DELETED = 'order_station_deleted',
  ORDER_STATION_ADDED = 'order_station_added',
  ORDER_MEMBER_ROLE_UPDATED = 'order_member_role_updated',
  ORDER_MEMBER_STATION_UPDATED = 'order_member_station_updated',
  USER_REVOKED_SESSION = 'user_revoked_session',

  NOTIFICATION = 'notification',
}
