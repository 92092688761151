import { ICamera } from './camera';
import { EGpsStatus } from './enum';
import { TLevelLog } from './log';

export enum EWorkingMode {
  CHECKIN = 'checkin',
  SCHEDULED = 'scheduled',
  FULLTIME = 'fulltime',
}

export enum EScheduledDay {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum ELightStatus {
  ON = 'ON',
  OFF = 'OFF',
}

export enum EStationAction {
  CREATE = 'create',
  UPDATE = 'update',
  CLOSE = 'close',
}

export enum EStationQuotaUnit {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export interface WorkingTime {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}

export interface WorkingSchedule {
  monday: WorkingTime[];
  tuesday: WorkingTime[];
  wednesday: WorkingTime[];
  thursday: WorkingTime[];
  friday: WorkingTime[];
  saturday: WorkingTime[];
  sunday: WorkingTime[];
}

export enum EPowerBatteryStatus {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
}

export enum EMobileNetworkStatus {
  DISCONNECT = 'disconnect',
  CONNECT = 'connect',
}

export interface IStation {
  id: string;
  name: string;
  lat: number;
  lng: number;

  address: string;
  province: number;
  district: number;
  commune: number;
  ip: string;
  portRtsp: string;
  portOnvif: string;
  currentStationCameraIdsList: string[];
  powerBattery: boolean;
  powerSolar: boolean;

  powerElectricLine: boolean;
  powerBatteryCapacityStatus: number;
  status: EStationStatus;
  powerBatteryChargeStatus: EBatteryChargeStatus;
  powerBatteryConnectionStatus: EPowerBatteryStatus;

  lightStatus: ELightStatus;
  gpsWaveStrength: number;
  gpsConnectionStatus: EGpsStatus;
  mobileNetwork: '3G' | '4G';
  gpsLastUpdatedAt: string;

  mobileNetworkWaveStrength: number;

  mobileNetworkOperator: string;
  mobileNetworkConnectionStatus: EMobileNetworkStatus;

  detailAddress: string;
  addressFull?: string;
  mobileNetworkQuota: number;
  mobileNetworkQuotaUnit: string;

  uuid: string;
  isEnableLight?: boolean;
  isEnableMic?: boolean;
  isEnableSpeaker?: boolean;

  cameraStatus: 'ON' | 'OFF' | 'STARTING';
  cameras?: ICamera[];

  powerBatteryLastUpdatedAt: string;
  mobileNetworkDataUsed: string;
  mobileNetworkLastUpdatedAt: string;

  workingMode: EWorkingMode;
  workingSchedule: WorkingSchedule;
}

export enum EBatteryChargeStatus {
  CHARGE = 'charge',
  DISCHARGE = 'discharge',
}
export enum EStationStatus {
  NORMAL = 'normal',
  WARNING = 'warning',
  ERROR = 'error',
  OFFLINE = 'offline',
  ACTIVE = 'activating',
  INACTIVE = 'inactive',
  CONNECTING = 'connecting',
}

export interface IStationGeneral {
  id: string;
  name: string;
  lat: number;
  lng: number;
  province: number;
  district: number;
  commune: number;
  address?: string;
  detailAddress: string;
  mobileNetworkQuota: number;
  mobileNetworkQuotaUnit: string;
  powerSolar: boolean;
  powerElectricLine: boolean;
  powerBattery: boolean;
  uuid: string;
  isEnableLight?: boolean;
  isEnableMic?: boolean;
  isEnableSpeaker?: boolean;
  cameras?: ICamera[];
}

export interface ILight {
  id: string;
  name: string;
  status?: ELightStatus;
}

export interface WorkingSchedule {
  monday: WorkingTime[];
  tuesday: WorkingTime[];
  wednesday: WorkingTime[];
  thursday: WorkingTime[];
  friday: WorkingTime[];
  saturday: WorkingTime[];
  sunday: WorkingTime[];
}

export interface IStationWorkingMode {
  mode: EWorkingMode;
  schedule: WorkingSchedule;
  stream: {
    token: string;
    resolution: {
      height: number;
      width: number;
    };
  };
}

export interface IStationFunction {
  functionEnabled: boolean;
  lights: ILight[];
  cameras: ICamera[];
  workingMode: EWorkingMode;
  workingSchedule: WorkingSchedule;
  currentStream: IStationFunctionStream;
  streams: IStationFunctionStream[];
}

export interface IStationFunctionStream {
  name: string;
  token: string;
  resolution: {
    width: number;
    height: number;
  };
  resolutionRangeList: {
    width: number;
    height: number;
  }[];
  quality: number;
  qualityRange: {
    min: number;
    max: number;
  };
  fps: number;
  fpsRange: {
    min: number;
    max: number;
  };
  streamEnabled: boolean;
  recordEnabled: boolean;
}

export interface IStationFilter {
  searchKeyword: string;
  province: string;
  district: string;
  commune: string;
  customer: string;
  status: string;
}

export interface IStationCard {
  id: string;
  name: string;
  lat: number;
  lng: number;
  battery: number;
  address: string;
  function?: IStationFunction;
}

export interface IStationFunctionDetail {
  id: string;
  status?: boolean;
  displayName: string;
  icon?: any;
  iconColor?: string;
}

export interface IFilterStation {
  searchKeyword: string;
  province: number[];
  district: number[];
  commune: number[];
  customerId: string[];
}

export interface IConfigUserStation {
  userId: string;
  payload: {
    trackAllStations: boolean;
    stations: string[];
  };
}

export interface IUpdateWorkingModeSocket {
  stationId: string;
  timestamp: number;
  newWorkingMode: {
    workingMode: EWorkingMode;
    workingSchedule: WorkingSchedule;
  };
}

export interface WorkingTimeForm {
  start: string | number;
  end: string | number;
}

export interface FormWorkingMode {
  mode: EWorkingMode;
  streamOption: { name: string; token: string };
  status: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  monday: WorkingTimeForm[];
  tuesday: WorkingTimeForm[];
  wednesday: WorkingTimeForm[];
  thursday: WorkingTimeForm[];
  friday: WorkingTimeForm[];
  saturday: WorkingTimeForm[];
  sunday: WorkingTimeForm[];
}

export interface IStationLog {
  level: TLevelLog;
  type: string;
  time: Date;
  title: string;
  description: string;
}

export interface IPaginationStationLogs<T> {
  totalPage: number;
  data: T[];
}
